<template>
    <content-view class="w-100">
        <search />
    </content-view>
</template>

<script>
    import { metaTitle } from '@/mixins/meta_title'

    export default {
        name: 'SearchView',
        mixins: [metaTitle],
        components: {
            ContentView: () => import('@/views/menu/ContentView'),
            Search: () => import('@/components/search/Search')
        }
    }
</script>

<style lang="scss" scoped></style>
